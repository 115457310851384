import { Helmet } from "react-helmet";
import styles from "./Cookies.module.css";


export function Cookies() {
    return (<>
      <Helmet>
                <meta charSet="utf-8" />
                <title> Politica Cookies | Carpathian Dentist | Cabinet dentar </title>
                <link rel="canonical" href="/cookies" />
                <meta name="description" content="Tratamente decontate prin CAS. Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate. Suna la 0757088840 si programeaza-te gratuit!" />
                <meta name="keywords" content="Tratamente decontate prin CAS, dentist, cabinet dentar moieciu, dentist moieciu de jos, clinica dentara" />
        <meta name="author" content="Politica de confidentialitate | Carpathian Dentist" />
        <meta property="og:title" content=" Politica de confidentialitate | Carpathian Dentist | Cabinet dentar" />
        <meta property="og:description" content="Tratamente decontate prin CAS. Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
        <meta property="og:image" content="https://i.imgur.com/gKhjL20.png" />
        <meta property="og:url" content="https://example.com/my-page" />
        <meta name="facebook:title" content=" Politica de confidentialitate | Carpathian Dentist | Cabinet dentar" />
        <meta name="facebook:description" content="Tratamente decontate prin CAS. Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
        <meta name="facebook:image" content="https://i.imgur.com/gKhjL20.png" />
        <meta name="facebook:card" content="https://i.imgur.com/Rcop7CM.png" />





            </Helmet>
    <h1>Politica de utilizare a Modulelor Cookie</h1>
<div className={styles["pagina"]}>
<p>Prezenta Politica de utilizare a Modulelor Cookie se aplica tuturor vizitatorilor website-ului www.carpathiandentist.ro, detinut si operat de Carpathian Dentist SRL, cu sediul in Moieciu de Jos, Brasov, inregistrata in Registrul Unic al Cabinetelor Medicale sub nr.J08/3352/2023 , avand Cod Unic de Inregistrare 49154606.
In cuprinsul acestei Politici veti gasi detalii referitoare la ce reprezinta modulele cookie, in ce scop sunt folosite si care sunt implicatiile pentru utilizatori ca urmare a acceptarii anumitor categorii de module cookie plasate pe Website.</p>
<ul><li><b>1.Ce sunt modulele cookies?</b></li>
<p>Notiunea de <b>"cookies" </b>se refera la modulele cookie si la orice alte tehnologii similare prin intermediul carora pot fi colectate automat informatii. </p>
<p>Cookie-urile sunt fisiere text de mici dimensiuni plasate de Website, prin intermediul browser-ului, pe dispozitivul pe care il utilizati pentru navigare (calculator, smartphone, etc.), care identifica dispozitivul in momentul accesarii Website-ului.  La fiecare accesare ulterioara a Website-ului, browserul trimite catre serverul Website-ului acest fisier, permitand astfel, identificarea unui vizitator care a revenit pe site.</p>
<li><b>2.Rolul Modulelor cookie</b></li>
<p>Modulele cookie sunt utilizate pentru a asigura o navigare functionala, pentru a oferi o experienta de navigare mai buna, pentru a realiza analize statistice privind accesarea informatiilor de pe Website, pentru a asigura securitatea Website-ului, dar si pentru a oferi publicitate adecvata si personalizata intereselor dumneavoastra.</p>
<li><b>3.Care este durata de viata a Modulelor cookiee?</b></li>
<p>Odata instalate, modulele cookie au o durata de existenta determinata, ramanand „pasive”, in sensul ca nu contin programe software, virusi sau spyware si nu vor accesa informatiile de pe hard driverul vizitatorului.</p>
<li><b>4.Categorii de Module cookie</b></li>
<li><b>4.1.	Module cookie de sesiune si module cookie permanente</b></li>
<p>In functie de durata modulului cookie, acestea pot fi cookie-uri de sesiune sau cookie-uri permanente.</p>
<li className={styles["lista"]}>	Un „cookie de sesiune” este un modul cookie care are o durata de stocare temporara, limitata doar la durata sesiunii in care vizitatorul acceseaza Website-ul, fiind sters automat cand vizitatorul isi inchide browserul.</li>

<li className={styles["lista"]}>	Un „cookie permanent” este un modul cookie care este stocat la nivelul dispozitivului utilizatorului si nu este sters atunci cand vizitatorul isi inchide browserul, finalizand sesiunea de navigare. </li>
<li><b>4.2.	Module cookie necesare, module cookie pentru statistici - de cercetare si analiza, si pentru marketing</b></li>
<p>In functie de scopul modulului cookie, acestea pot fi cookie-uri necesare, de cercetare si analiza sau de marketing.</p>
<li className={styles["lista"]}>	<b>Modulele cookie necesare </b>va permit sa navigati pe Website-ul nostru si sa utilizati functiile acestuia. Acestea sunt estentiale pentru buna functionare a Website-ului si nu pot fi dezactivate.
De asemenea, acestea asigura securitatea Website-ului. Aceste module cookie nu colecteaza informatii despre dumneavoastra care ar putea fi folosite pentru publicitate sau pentru a va inregistra activitatea de navigare. De regula, acestea sunt utilizate pentru a furniza raspuns la anumite actiuni ale utilizatorului si nu stocheaza date cu caracter personal.</li>


<li className={styles["lista"]}>	<b>	Modulele cookie pentru statistici </b>de cercetare si analiza sunt utilizate in scopul de analiza a traficului si/sau a performantelor din Website, precum si pentru a indica daca un anumit vizitator al Website-ului a mai vizitat/utilizat anterior Website-ul, precum si frecventa si numarul vizitelor in Website. 
Aceste module cookie sunt utilizate doar in scopuri statistice, de cercetare si analiza.</li>

<li className={styles["lista"]}>	<b>Modulele cookie pentru marketing</b> sunt utilizate pentru targetarea publicitatii online. Aceste module cookie stocheaza informatii referitoare la continutul vizualizat si au ca scop afisarea de reclama relevanta si particularizata conform intereselor utilizatorilor.</li>

<li><b>4.3.	Module cookie proprii si module cookie furnizate de terti</b></li>
<p>In functie de furnizorul modulului cookie, acestea pot fi cookie-uri proprii (i.e., plasate de Cabinetul medical pe Website), cat si cookie-uri de la terti, plasate de catre acestia. Modulele cookie ne permit sa intelegem cerintele utilizatorilor Website-ului, in scopul imbunatatirii experientei pe care acestia o au in utilizarea Webstite-ului si in obtinerea informatiilor dorite.</p>

<li><b>5.Module cookie plasate Website-ul nostru</b></li>

<li><b>5.1.	Module cookie necesare</b></li>

<li><b>5.2.	Module cookie pentru statistici  de cercetare si analiza</b></li>
<li><b>5.3.	Modulele cookie pentru marketing</b></li>
<li><b>6.Transferul datelor dumneavoastra in afara Spatiului Economic European (SEE)</b></li>
<p>In anumite situatii, Cabinetul medical ar putea sa transfere date anonime si/sau agregate, rezultand din modulele cookie plasate pe Website, catre organizatii din afara SEE. In cazul in care are loc un astfel de transfer, Cabinetul medical se va asigura ca sunt implementate masuri de securitate care sa asigure siguranta si integritatea acestor date.</p>
<li><b>7.Gestionarea modulelor cookie</b></li>
<p>Puteti configura modul in care Cabinetul medical utilizeaza anumite categorii de modulele cookie plasate pe Website, respectiv va puteti exprima consimtamantul pentru utilizarea de module cookie pentru statistici - de cercetare si analiza, si pentru marketing sau puteti opta pentru utilizarea Website-ului fara utilizarea acestor categorii de module cookie. In acest din urma caz, Cabinetul medical va utiliza exclusiv modulele cookie necesare pentru functionarea Website-ului. </p>
<p>Puteti reveni oricand asupra optiunii dumneavoastra accesand sectiunea: Gestionare module cookie.</p>
<p>De asemenea, puteti modifica setarile modulelor cookie, precum si sterge modulele cookie existente, accesand setarile browserului pe care il utilizati, in sectiunea „Optiuni” sau in meniul „Preferinte” al browserului, dupa cum urmeaza: </p>
<li className={styles["lista"]}><a href="https://support.microsoft.com/ro-ro/windows/gestionarea-modulelor-cookie-%C3%AEn-microsoft-edge-vizualizarea-permiterea-blocarea-%C8%99tergerea-%C8%99i-utilizarea-168dab11-0753-043d-7c16-ede5947fc64d">Cookie settings in Internet Explorer</a></li>
<li className={styles["lista"]}><a href="https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=a81d90ac-aa75-4cf8-b14c-58bf348528fe&redirect_uri=https%3A%2F%2Fanswers.microsoft.com&response_type=code%20id_token&scope=openid%20profile&state=OpenIdConnect.AuthenticationProperties%3DpOL21FHaqUmcwjlEJRObPAwOGz0qrOH5QLync1kI0GMZm1YLzLteJXuOeht8FCYUh1xwGVIcYmGUXVjgXK3O0jNwITVeTQ9Pv2H9dak9s8E210vimWX3ZTEIQJesd1T2AVJeRdfkRpmUlMBdXh8S_dEkd670Uz0DVRh_HJNzdrI0rIfK31KiHO1O9DeV3be_f1nnIKWHIWyCjqSfAykv-m8t-vFhs9mAWX8y8FQ1JRDYYLtWqtQ5Lqx_RcehOnPdgojskJsxcwguEIdGcMxbuhLARop7Je7HEYCfXJknt3gdcVIBxHOTg2ocOmp85XaO8zW9QT6dC-hARwj1eOuzjw9qYz4o74CeIgYYsl8QV-CT2V4aiN8zFC8INwfwwWUj9YKdQ6TReCx8V4nXr_PrbMF_dc1iY3wJRWI6MnE9YWCyB_duDO56zGzmu4PB7rbFjAWW9Qq2nIIpHDYulhDItzV-HxA5e4L99XFVB27SCWYdhhB3C8EvfRGT9YDaYem5iG7hDAlDZuHxj-sozV4SBDZyX1AcSYLp1cu_YjOPgSLnO2jcTdsuP9kr8K1gIiPb&response_mode=form_post&nonce=638741707670635913.N2YxMjE0NDctYzA0Ny00NjlmLWE1NTMtMTk2MWU3ZmNkMzQ4YWUxODhmMzUtZWJhMy00ZDkzLWFkMDUtNzJjOTFhMGJjNzE1&nopa=2&prompt=none&x-client-SKU=ID_NET472&x-client-ver=7.6.0.0">Cookie settings in Microsoft Edge</a></li>
<li className={styles["lista"]}><a href="https://support.mozilla.org/ro/kb/activarea-si-dezactivarea-cookie-urilor?utm_source=search&utm_medium=cpc&utm_content=EN&utm_campaign=Collection-Ongoing&gclid=EAIaIQobChMI2oH8ucDRiAMVlKloCR0Lozg0EAAYASAAEgIyh_D_BwE">Cookie settings in Firefox</a></li>
<li className={styles["lista"]}><a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop%21%24&utm_source=search&utm_medium=cpc&utm_content=EN&utm_campaign=Collection-Ongoing&gclid=EAIaIQobChMI2oH8ucDRiAMVlKloCR0Lozg0EAAYASAAEgIyh_D_BwE#amp#%24!hl=ro">Cookie settings in Chrome for Desktop </a></li>
<li className={styles["lista"]}><a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid%21%24&utm_source=search&utm_medium=cpc&utm_content=EN&utm_campaign=Collection-Ongoing&gclid=EAIaIQobChMI2oH8ucDRiAMVlKloCR0Lozg0EAAYASAAEgIyh_D_BwE#amp#%24!hl=ro">Cookie settings in Chrome for Android</a></li>
<li className={styles["lista"]}><a href="https://support.apple.com/ro-ro/guide/safari/sfri11471/mac?utm_source=search&utm_medium=cpc&utm_content=EN&utm_campaign=Collection-Ongoing&gclid=EAIaIQobChMI2oH8ucDRiAMVlKloCR0Lozg0EAAYASAAEgIyh_D_BwE">Cookie settings in Safari </a></li>
<p>Ne puteti adresa orice intrebari legate de modulele cookie plasate pe Website-ul nostru prin utilizarea urmatoarelor date de contact: </p>
<p><b>Adresa postala:</b> MOIECIU DE JOS, Str. DN 73F, Nr. 351A, județ BRAȘOV</p>
<p><b>Adresa de e-mail:</b><a href="mailto:dr.cosmin.musuroi@gmail.com"> dr.cosmin.musuroi@gmail.com</a></p>

</ul>


</div>
    </>)


}

import { Helmet } from "react-helmet";
import styles from "./PoliticaConfidentialitate.module.css";

export function PoliticaConfidentialitate(){
 
return(
<>
<Helmet>
                <meta charSet="utf-8" />
                <title> Politica de confidentialitate | Carpathian Dentist | Cabinet dentar </title>
                <link rel="canonical" href="/politica-confidentialitate" />
                <meta name="description" content="Tratamente decontate prin CAS. Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate. Suna la 0757088840 si programeaza-te gratuit!" />
                <meta name="keywords" content="Tratamente decontate prin CAS, dentist, cabinet dentar moieciu, dentist moieciu de jos, clinica dentara" />
        <meta name="author" content="Politica de confidentialitate | Carpathian Dentist" />
        <meta property="og:title" content=" Politica de confidentialitate | Carpathian Dentist | Cabinet dentar" />
        <meta property="og:description" content="Tratamente decontate prin CAS. Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
        <meta property="og:image" content="https://i.imgur.com/gKhjL20.png" />
        <meta property="og:url" content="https://example.com/my-page" />
        <meta name="facebook:title" content=" Politica de confidentialitate | Carpathian Dentist | Cabinet dentar" />
        <meta name="facebook:description" content="Tratamente decontate prin CAS. Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
        <meta name="facebook:image" content="https://i.imgur.com/gKhjL20.png" />
        <meta name="facebook:card" content="https://i.imgur.com/Rcop7CM.png" />





            </Helmet>
<div className={styles["pagina"]}>
<h4>POLITICA DE PRELUCRARE A DATELOR CU CARACTER PERSONAL PRIVIND WEBSITE-UL WWW.CARPATHIANDENTIST.RO</h4>
    <ol>
        <li><strong>CINE SUNTEM:</strong></li>
        <p>  SC CARPATHIAN DENTIST SRL cu sediul in Moieciu de Jos, str.DN71A, inregistrata la Registrul Comertului cu numarul J08/3352/2023, CUI 49154606 in in calitate de autor, proprietar si administrator al website-ului www.carpathiandentist.ro si in calitate de Operator in sensul Regulamentului General privind Protectia Datelor nr. 679/2016 („RGPD”) prelucreaza date cu caracter personal, angajandu-se in acest sens sa respecte dreptul la viata privata al persoanelor care acceseaza Website-ul si sa asigure protectia adecvata a prelucrarii datelor cu caracter personal pentru toate persoanele care utilizeaza Website-ul.</p>
       <p>Cu privire la anumite operatiuni de prelucrare a datelor (de ex. atunci cand categoriile de date prelucrate si mijloacele si scopurile prelucrarii sunt decise impreuna cu alte entitati), Cabinetul medical actioneaza in calitate de operator asociat in conformitate cu RGPD. Va indicam in aceasta Politica informatiile relevante privind aceste operatiuni/situatii.</p>


       <li><strong>CUM NE PUTETI CONTACTA:</strong></li>
      <p>Ne puteti contacta oricand pentru informatii si lamuriri suplimentare cu privire la prelucrarea datelor dumneavoastra cu caracter personal folosind urmatoarele date de contact:</p>
<p>Adresa postala: Moieciu de Jos, str.DN71A</p>
<p>Adresa de e-mail: <a href="mailto:dr.cosmin.musuroi@gmail.com">dr.cosmin.musuroi@gmail.com</a></p>
<p>Telefon:<a href="tel:0752859298">0752 859 298</a></p>

<li><strong>INFORMATII GENERALE:</strong></li>
<p>Scopul acestei Politici de prelucrare a datelor cu caracter personal („Politica”) este sa va explice ce date cu caracter personal prelucram prin intermediul Website-ului, de ce le prelucam si in ce mod o facem</p>
<p>Astfel, in cadrul aceste Politici gasiti informatii detaliate cu privire la:</p>
<ul className={styles["bullet"]}>
<li>categoriile de persoane ale caror date cu caracter personal sunt prelucrate prin intermediul Website-ului;</li>
<li>	datele cu caracter personal prelucrate;</li>
<li>	datele cu caracter personal prelucrate;</li>
<li>	temeiul juridic pe care ne bazam atunci cand prelucram datele dumneavoastra cu caracter personal;</li>
<li>	perioadele depastrare a datelor dumneavoastra cu caracter personal;</li>
<li>	persoanele cu care partajam datele dumneavoastra, precum si scopul in care le divulgam;</li>
<li>	drepturile dumneavoastra in calitate de persoana vizata, si modul in care va puteti exercita aceste drepturi.</li>
</ul>
<p>In cazurile in care furnizarea datelor de catre dumneavoastra si implicit, prelucrarea acestora, este indicata expres in Politica drept necesara (spre exemplu: pentru executarea obligatiilor contractuale, indeplinirea obligatiilor noastre– inclusiv cele legale, sau realizarea unui interes legitim), nefurnizarea de catre dumneavoastra a respectivelor date cu caracter personal va duce la imposibilitatea asigurarii serviciului/ functionalitatilor Website-ului sau dupa caz, va atrage In aceste cazuri nu vom fi raspunzatori daca nu veti putea beneficia de serviciile/functionalitatile Website-ului.</p>
<p>In cazul in care anumite aspecte din acest document sunt neclare sau considerati ca sunt, va rugam sa ne semnalati aceste situatii cu utilizarea datelor de contact prezentate in sectiunea „CUM NE PUTETI CONTACTA”. Vom incerca sa solutionam orice situatie semnalata, scopul nostru fiind sa detineti toate informatiile necesare si sa intelegeti conditiile in care Cabinetul medical prelucreaza datele dumneavoastra cu caracter personal.</p>
<p>Prevederile prezentei Politici se aplica cu prioritate fata de alte prevederi din orice alte documente, disponibile si/sau comunicata de Cabinetul medical cu privire la Website si care privesc apecte asociate prelucrarilor de date cu caracter personal prin intermediul Website-ului.</p>


<li><strong>APLICABILITATE – PERSOANELE VIZATE</strong></li>
<ul className={styles["bullet"]}>
<li><b>Vizitatori</b> – respectiv persoanele care acceseaza Website-ul;</li>
<li><b>Utilizatori</b> – respectiva persoanele care acceseaza Website-ul si isi creeaza cont, in mod voluntar, efectuand programari si alte actiuni prin intermeiul acestui cont;</li>
<li><b>Pacienti</b> – respectiv persoanele care acceseaza Website-ul (ca Vizitator sau Utilizator) si efectueaza o programare prin intermediul acestuia sau telefonic.</li>
</ul>
<p>Desi puteti accesa continutul Website-ului fara a furniza date cu caracter personal, cu exceptia cookie-urilor (in acest sens va rugam sa consultati Politica de utilizare a modulelor cookie pe Website, disponibila aici….), in cazul in care introduceti online pe Website date cu caracter personal, Cabinetul medical le poate utiliza ulterior in scopuriledeclarate in prezenta Politica.</p>
<p><b>! Atentionam Vizitatorii</b>, Utilizatorii si Pacientii in mod expres ca utilizarea Website-ului de catre persoane care nu au implinit varsta de 18 ani este interzisa. </p>
<p>Prelucram date ale persoanelor cu varsta sub 18 ani, doar cu acordul reprezentantului legal, pentru desfasurarea in conditii optime a serviciilor contractate de catre reprezentantii legali ai minorilor. In cazul in care are loc un eveniment care are drept consecinta prelucrarea datelor cu caracter personal ale copiilor sub 18 ani in alte situatii decat cea prezentata in prezentul paragraf, vom sista prelucrarea acestor date imediat ce vom afla un astfel de fapt.</p>

<p>Este posibil sa primim informatii referitoare la persoane care nu au implinit varsta de 18 ani prin fraudarea sau inselaciunea unui tert. Daca suntem instiintati despre acest lucru, de indata ce vom verifica informatiile, atunci cand legea cere acest lucru, vom obtine imediat consimtamantul reprezentantului legal pentru a utiliza aceste informatii sau, daca nu putem obtine acest consimtamant, vom sterge informatiile de pe serverele noastre. </p>
<p>Daca doriti sa ne anuntati primirea de informatii despre persoanele cu varsta sub 18 ani, va rugam sa faceti acest lucru prin trimiterea unui e-mail la adresa <a href="mailto:dr.cosmin.musuroi@gmail.com">dr.cosmin.musuroi@gmail.com </a></p>
<li><strong>DATELE CU CARACTER PERSONAL PRELUCRATE. SCOPURILE PRELUCRARII DATELOR. TEMEIUL LEGAL</strong></li>
<ol className={styles["a"]}>
<li><strong>Accesarea Website-ului</strong></li>


<p><strong>Datele cu caracter personal prelucrate:</strong></p>
<p>In momentul in care dumneavoastra, Vizitatorul, interactionati cu Website-ul (accesati/ vizitati), anumite informatii sunt transferate in mod automat intre dispozitivul dumneavoastra de pe care accesati Website-ul si serverul nostru, care inregistreaza automat informatiile astfel transferate. </p>
<p>In functie de modalitatea in care accesati Website-ul, acestea pot fi: adresa IP a dispozitivului conectat la internet pe care il utilizati pentru a accesa Website-ul, paginile accesate in timpul vizitei dumneavoastra pe Website, informatiile solicitate, data si ora solicitarii, sursa accesulului la Website-ul nostru (de exemplu, site-ul web sau URL-ul www.carpathaindentist.ro, versiunea browserului si a sistemului de operare al dispozitivului.)</p>
<table className={styles["tabel"]}>
<tr>
    <td>Descrierea prelucrarii</td>
    <td>Scopul prelucrarii</td>
    <td>Temeiul legal conform RGPD</td>
  </tr>
  <tr>
    <td>Asigurarea conexiunii, accesului si utilizarii optime a Website-ului, serviciilor si functionalitatilor din Website</td>
    <td>Asigurarea accesului la Website</td>
    <td>Art. 6 al. 1 lit. b - Executarea unor demersuri/masuri precontractuale sau executarea contractului (a termenilor si conditiilor de utilizare a serviciilor Website-ului)</td>
  </tr>
  <tr>
    <td>Colectarea, stocarea si analizarea urmelor informatice, precum si evaluarea sigurantei si stabilitatii sistemelor informatice prin care este operat Website-ul</td>
    <td>Securitatea sistemelor informatice</td>
    <td>Art. 6 al. 1 lit. f – interesul legitim al cabinetului medical de a identifica si preveni accesul neautorizat la retea si a asigura securitatea sistemelor informatice</td>
  </tr>
</table>
<li><strong>Comunicarea cu Cabinetul medical (transmiterea de intrebari, solicitari, reclamatii)</strong></li>
<p>In functie de canalul de comunicare prin care ne contactati, prelucram anumite date cu caracter personal in vederea primirii si gestionarii intrebarilor, solicitarilor si reclamatiilor dumneavoastra, astfel cum este detaliat mai jos.</p>
<li><strong>Prin e-mail</strong></li>
<p><strong>Datele cu caracter personal prelucrate:</strong></p>
<p>Cand trimiteti un mesaj la adresa de e-mail aferenta corespondentei privind Serviciul Relatii cu Clientii, astfel cum este afisata pe Website, colectam adresa dumneavoastra de e-mail si orice alte informatii pe care le furnizati in respectivul e-mail, precum: numele dumneavoastra, numarul de telefon si informatiile continute in orice bloc de semnatura din e-mail. </p>
<li><strong>Prin formularul de contact disponibil pe Website</strong></li>
<p><strong>Datele cu caracter personal prelucrate:</strong></p>

<p>Cand ne contactati utilizand formularul de contact, colectam: nume, prenume, e-mail, numar de telefon.  De asemenea, colectam orice alte informatii pe care ni le furnizati atunci cand completati formularul de contact in campul care contine mesajul dumneavoastra. </p>
<p>Daca nu furnizati informatiile cerute de formularul de contact, nu il veti putea trimite si nu vom primi solicitarea dumneavoastra, prin urmare nu vom putea raspunde.</p>
<li><strong>Prin apel telefonic</strong></li>
<p><strong>Datele cu caracter personal prelucrate:</strong></p>
<p>Cand ne contactati prin telefon, colectam numarul dumneavoastra de telefon si orice informatii pe care ni le furnizati in timpul conversatiei cu noi. </p>
<p>Cabinetul medical inregistreaza apelurile telefonice efectuate de catre dumneavoastra. Astfel incat putem prelucra si vocea dumneavoastra (inregistrare convorbiri telefonice), doar cu acordul prealabil al dumneavoastra.</p>
<p>In cazul in care nu va exprimati acordul cu privire la prelucrarea vocii dumneavoastra, nu vom putea continua apelul telefonic si in consecinta, nu vom putea raspunde la intrebarile dumneavoastra.</p>
<li><strong>Prin posta</strong></li>
<p><strong>Datele cu caracter personal prelucrate:</strong></p>
<p>Cand ne contactati prin posta, vom colecta numele si prenumele dumneavoastra, adresa postala si/sau orice alte informatii care se regasesc in comunicarea postala transmisa.</p>
<p>In lipsa furnizarii adresei postale si a datelor de identificare, desi vom prelua solicitarea, nu vom putea reveni catre dumneavoastra cu un raspuns la solicitarea transmisa.</p>

<table>
<tr>
    <td>Descrierea prelucrarii</td>
    <td>Scopul prelucrarii</td>
    <td>Temeiul legal conform RGPD</td>
  </tr>
  <tr>
    <td>Primirea si gestionarea intrebarilor, solicitarilor si reclamatiilor legate de Website sau legate de serviciile si produsele oferite de Cabinetul medical</td>
    <td>Suport si asistenta Vizitatori si Utilizatori </td>
    <td>Art. 6 al. 1 lit. f - interesul legitim al Cabinetului medical de a asigura functionarea corecta a Website-ului precum si pentru a imbunatati permanent experienta vizitatorilor inclusiv prin solutionarea diferitelor intrebari sau reclamatii</td>
  </tr>
  <tr>
    <td>Primirea si gestionarea intrebarilor, solicitarilor si reclamatiilor</td>
    <td>Suport si asistenta Pacienti</td>
    <td>Art. 6 al. 1 lit. f – interesul legitim al Cabinetului medical de a asigura un serviciu eficient de Relatii Clienti</td>
  </tr>
</table>

<li><strong>Efectuarea unei programari</strong></li>
<p>Vizitatorii Website-ului au la dispozitie doua modalitati pentru a efectua o programare pentru unul din serviciile oferite de Cabinetul medical: telefonic, la numarul de contact sau prin intermediul formularului de programare online, disponibile pe Website. Pentru efectuarea programarii in vederea prestarii serviciilor medicale, ne sunt necesare datele Pacientului (datele dumneavoastra sau datele persoanei pentru care efectuati programarea, spre exemplu – un minor).</p>
<li><strong>Prin intermediul Website-ului – formularul de programare online</strong></li>
<p><strong>Datele cu caracter personal prelucrate:</strong></p>
<p>Cand efectuati o programare pe Website, in calitate de Vizitator, colectam: nume, prenume, varsta, numar de telefon, e-mail si orice informatii pe care dumneavoastra le furnizati suplimentar cu privire la programare.</p>
<p>Daca nu furnizati aceste informatii, nu veti putea sa transmiteti formularul de programare si in consecinta, nu veti putea efectua o programare prin intermediul Website-ului.</p>
<p>In cazul in care efectuati programarea pentru o alta persoana, spre exemplu – un minor, vom colecta datele dumneavoastra de contact si datele de identificare ale minorului (in calitate de pacient), precum si orice alte informatii cu privire la programare, astfel cum sunt furnizate de catre dumneavoastra, in mod direct si voluntar.</p>
<table>
<tr>
    <td>Descrierea prelucrarii</td>
    <td>Scopul prelucrarii</td>
    <td>Temeiul legal conform RGPD</td>
  </tr>
  <tr>
    <td>Plasarea si confirmarea programarii</td>
    <td>Procesarea programarilor </td>
    <td>Art. 6 al. 1 lit. b - Executarea contractului incheiat cu dumneavoastra</td>
  </tr>
  <tr>
    <td>Corespondenta (ex. status programare). Gestionarea cererilor, sesizarilor si reclamatiilor Pacientului in procesul de plasare si confirmare programare, precum si furnizare servicii medicale. Furnizarea de asistenta si suport Pacient.</td>
    <td>Suport si asistenta Pacienti</td>
    <td>Art. 6 al. 1 lit. b - Executarea contractului incheiat cu dumneavoastra</td>
  </tr>
  <tr>
    <td>Culegerea de opinii, prin intermediul chestionarelor de feedback, cu privire la serviciile furnizate, precum si analizarea acestora</td>
    <td>Evaluarea satisfactiei Pacientilor</td>
    <td>Art. 6 al. 1 lit. a – Consimtamantul dumneavoastra, atunci cand alegeti sa il exprimati</td>
  </tr>
</table>

<li><strong>Categorii speciale de date cu caracter personal</strong></li>

<p>Categoriile speciale de date cu caracter personal date cu caracter personal care dezvaluie originea rasiala sau etnica, opinii politice, convingeri religioase sau filosofice sau apartenenta sindicala si prelucrarea datelor genetice, date biometrice in scopul identificarii unice a unei persoane fizice, date privind sanatatea sau date privind viata sexuala sau orientarea sexuala a unei persoane fizice.</p>
<p>In acest sens, vom colecta si prelucra informatiile sensibile, facand parte din categoriile speciale de date cu caracter personal, care ne vor fi furnizate direct de catre dumneavoastra, in mod voluntar, in momentul efectuarii unei programari, precum: </p>

<ul className={styles["bullet"]}>
    <li>Date personale privind sanatatea (de exemplu: afectiuni, investigatii, interventii, tratamente precedente);</li>
    </ul>
<table>
<tr>
    <td>Descrierea prelucrarii</td>
    <td>Scopul prelucrarii</td>
    <td>Temeiul legal conform RGPD</td>
  </tr>
  <tr>
    <td>Asigurarea conditiilor desfasurarii programarii si realizarii actului medical</td>
    <td>Gestionarea programarii si a actului medical solicitat </td>
    <td>Art. 9 al. 2 lit. h – realizarea actului medical si gestionarea serviciilor de sanatate</td>
  </tr>
  
</table>
<li><strong>Telefonic</strong></li>
<p><strong>Datele cu caracter personal prelucrate:</strong></p>
<p>Cand ne contactati prin telefon pentru a efectua o programare, colectam: nume, prenume, varsta, numar de telefon si dumneavoastra de telefon si orice informatii pe care dumneavoastra le furnizati suplimentar cu privire la programare.</p>
<p>Cabinetul medical inregistreaza apelurile telefonice efectuate de catre dumneavoastra. Astfel incat putem prelucra si vocea dumneavoastra (inregistrare convorbiri telefonice), doar cu acordul prealabil al dumneavoastra.</p>
<p>In cazul in care nu va exprimati acordul cu privire la prelucrarea vocii dumneavoastra, nu vom putea continua apelul telefonic si in consecinta, nu vom putea raspunde la intrebarile dumneavoastra.</p>
<p>In cazul in care efectuati programarea pentru o alta persoana, spre exemplu – un minor, vom colecta datele dumneavoastra de contact si datele de identificare ale minorului (in calitate de pacient), precum si orice alte informatii cu privire la programare, astfel cum sunt furnizate de catre dumneavoastra, in mod direct si voluntar.</p>

<table>
<tr>
    <td>Descrierea prelucrarii</td>
    <td>Scopul prelucrarii</td>
    <td>Temeiul legal conform RGPD</td>
  </tr>
  <tr>
    <td>Plasarea si confirmarea programarii</td>
    <td>Procesarea programarilor </td>
    <td>Art. 6 al. 1 lit. b - Executarea contractului incheiat cu dumneavoastra</td>
  </tr>
  <tr>
    <td>Corespondenta (ex. status programare). Gestionarea cererilor, sesizarilor si reclamatiilor Pacientului in procesul de plasare si confirmare programare, precum si furnizare servicii medicale. Furnizarea de asistenta si suport Pacient</td>
    <td> Suport si asistenta Pacienti</td>
    <td>Art. 6 al. 1 lit. b - Executarea contractului incheiat cu dumneavoastra</td>
  </tr>
  <tr>
    <td>Culegerea de opinii, prin intermediul chestionarelor de feedback, cu privire la serviciile furnizate, precum si analizarea acestora</td>
    <td>Evaluarea satisfactiei Pacientilor </td>
    <td>Art. 6 al. 1 lit. a – Consimtamantul dumneavoastra, atunci cand alegeti sa il exprimati</td>
  </tr>
  
</table>


<li><strong>Categorii speciale de date cu caracter personal</strong></li>
<p>Categoriile speciale de date cu caracter personal date cu caracter personal care dezvaluie originea rasiala sau etnica, opinii politice, convingeri religioase sau filosofice sau apartenenta sindicala si prelucrarea datelor genetice, date biometrice in scopul identificarii unice a unei persoane fizice, date privind sanatatea sau date privind viata sexuala sau orientarea sexuala a unei persoane fizice.</p>
<p>In acest sens, vom colecta si prelucra informatiile sensibile, facand parte din categoriile speciale de date cu caracter personal, care ne vor fi furnizate direct de catre dumneavoastra, in mod voluntar, in momentul efectuarii unei programari, precum: </p>

<ul className={styles["bullet"]}>
    <li>Date personale privind sanatatea (de exemplu: afectiuni, investigatii, interventii, tratamente precedente);</li>
    </ul>
    <table>
<tr>
    <td>Descrierea prelucrarii</td>
    <td>Scopul prelucrarii</td>
    <td>Temeiul legal conform RGPD</td>
  </tr>
  <tr>
    <td>Asigurarea conditiilor desfasurarii programarii si realizarii actului medical</td>
    <td>Gestionarea programarii si a actului medical solicitat </td>
    <td>Art. 9 al. 2 lit. h – realizarea actului medical si gestionarea serviciilor de sanatate</td>
  </tr>
 
  
</table>
<li><strong>Inregistrarea pe Website</strong></li>
<p><strong>Datele cu caracter personal prelucrate:</strong></p>
<p>Cand va inregistrati si creati un cont pe Website, colectam urmatoarele informatii informatii: nume, prenume, adresa de e-mail, telefon, varsta etc</p>
<p>Daca nu furnizati toate informatiile obligatorii cerute de formularul de inregistrare, nu va veti putea inregistra sau crea un cont pe Website si nu veti beneficia de functionalitatile asociate contului.</p>
<p>Cu toate acestea, lipsa crearii unui cont nu afecteaza in niciun fel posibilitatea dumneavoastra de a naviga pe Website si de a accesa serviciile puse la dispozitie prin intermediul acestuia. </p>

<table>
<tr>
    <td>Descrierea prelucrarii</td>
    <td>Scopul prelucrarii</td>
    <td>Temeiul legal conform RGPD</td>
  </tr>
  <tr>
    <td>Crearea contului (inclusiv efectuarea formalitatilor prealabile de validare a crearii contului) pentru utilizarea serviciilor/functionalitatilor asociate contului</td>
    <td>Creare cont – care permite Utilizatorului:<br></br>
a.    efectuarea de programari si alte actiuni in Website asociate contului;<br></br>
b.    accesul la unele servicii, functionalitati si beneficii in Website<br></br>
 </td>
    <td>Art. 6 al. 1 lit. b – Executarea unor demersuri / masuri precontractuale </td>
  </tr>
 
  
</table>

<p>Informatiile pe care ni le furnizati prin intermediul formularului de inregistrare de pe Website vor fi stocate pe serverele unui tert imputernicit, furnizorul nostru de servicii de gazduire web namebox.ro.</p>

<li><strong>Administrarea contului pe Website</strong></li>
<p><strong>Datele cu caracter personal prelucrate:</strong></p>

<p>Utilizatorul se logheaza (acceseaza contul din Website) furnizand adresa de e-mail cu care este inregistrat si introducand parola pe care si-a ales-o la crearea contului (aceste date folosite in logare sunt denumite colectiv “Date de acces”).</p>
<p>Ulterior logarii, Utilizatorul poate efectua diverse operatiuni sau transmite solicitari care implica colectarea si prelucrarea de catre Cabinetul medical a unor date, inclusiv categorii speciale de date cu caracter personal, cum ar fi:</p>
<ul className={styles["bullet"]}>
<li>datele de acces in cont;</li>
<li>datele initial colectate la crearea contului;</li>
<li>date personale noi/modificate, furnizate de Utilizator la momentul editarii/modificarii datelor furnizate la crearea contului (cu exceptia adresei de e-mail) sau cu ocazia completarii profilului;</li>
<li>date de facturare pe care Utilizatorul le adauga in cont, spre exemplu: adresa de facturare, (oras/sector, strada, numarul strazii, bloc, scara, etaj, apartament, cod postal);</li>
<li>date administrate in cont: istoric programari (detalii programari anterioare), istoric medical (investigatii, interventii, tratamente precedente); </li>
<li>interactiunea Utilizatorului cu comunicarile primite de la Cabinetul medical;</li>
<li>alte date: recenzii la serviciile medicale de care Utilizatorul a beneficiat in cadrul Cabinetului medical;</li>
    </ul>


    <table>
<tr>
    <td>Descrierea prelucrarii</td>
    <td>Scopul prelucrarii</td>
    <td>Temeiul legal conform RGPD</td>
  </tr>
  <tr>
    <td>Administrarea contului pentru oferirea acesului si utilizarii informatiilor din cont, serviciilor si functionalitatilor aferente contului </td>
    <td>Administrarea contului 
 </td>
    <td>Art. 6 al. 1 lit. b - Executarea contractului incheiat cu dumneavoastra </td>
  </tr>
 
  <tr>
    <td>Furnizarea facturii catre Pacient (si, dupa caz, alte documente financiar-contabile justificative)</td>
    <td>Emiterea facturii si a documentelor financiar -contabile justificative</td>
    <td>Art. 6 al. 1 lit. c - Obligatie legala</td>
  </tr>
  <tr>
    <td>Asigurarea de asistenta si suport (furnizarea de clarificari si raspunsuri) la solicitarile privind functionarea contului (ex. disponibilitatea sau indisponibilitatea unor functionalitati)</td>
    <td>Asistenta si suport cont de client</td>
    <td>Art. 6 al. 1 lit. c - Obligatie legala</td>
  </tr>
  <tr>
    <td>Prelucrarea prin analiza datelor furnizate (ex. recenzii) pentru imbunatatirea serviciilor medicale oferite, structurarea programelor de loializare/beneficiilor</td>
    <td>Imbunatatirea serviciilor Cabinetului medical, imbunatatirea/evaluarea produselor comercializate, concepere/structurare beneficii/programe loializare</td>
    <td>Art. 6 al. 1 lit. f RGPD – Interesul legitim al cabinetului medical de a asigura imbunatatirea serviciilor prestate si furnizarea unor servicii calitative</td>
  </tr>
</table>

<li><strong>Categorii speciale de date cu caracter personal</strong></li>
<p>Categoriile speciale de date cu caracter personal date cu caracter personal care dezvaluie originea rasiala sau etnica, opinii politice, convingeri religioase sau filosofice sau apartenenta sindicala si prelucrarea datelor genetice, date biometrice in scopul identificarii unice a unei persoane fizice, date privind sanatatea sau date privind viata sexuala sau orientarea sexuala a unei persoane fizice.</p>
<p>In acest sens, vom colecta si prelucra informatiile sensibile, facand parte din categoriile speciale de date cu caracter personal, care: (i) ne vor fi furnizate direct de catre dumneavoastra, in mod voluntar, in momentul inregistrarii pe Website si/sau cu ocazia efectuarii unei programari si/sau cu ocazia prezentarii in cadrul Cabinetului medical pentru obtinerea de servicii medicale sau (ii) vor fi obtinute de catre noi ca urmare a realizarii actului medical si/sau a diagnosticarii: </p>
<ul className={styles["bullet"]}>
<li>Date personale privind sanatatea (de exemplu: afectiuni, investigatii, interventii, tratamente precedente);</li>

</ul>
<table>
<tr>
    <td>Descrierea prelucrarii</td>
    <td>Scopul prelucrarii</td>
    <td>Temeiul legal conform RGPD</td>
  </tr>
  <tr>
    <td>Administrarea programarilor efectuate prin intermediul contului creat pe Website si pastrarea unei evidente corecte a dosarelor medicale </td>
    <td>Gestionarea si evidenta programarilor si a actelor medicale solicitate; accesul dumneavoastra la dosarul medical, continand (investigatii, interventii, tratamente precedente).
 </td>
    <td>Art. 9 al. 2 lit. h – realizarea actului medical si gestionarea serviciilor de sanatate </td>
  </tr>
 
 
</table>

<li><strong>Marketing direct</strong></li>
<p><strong>Datele cu caracter personal prelucrate:</strong></p>
<p>Pe parcursul interactiunii dumneavoastra cu Cabinetul medical prin intermediul Website-ului (spre exemplu: cand navigati pe Website ca Vizitator), veti putea opta sa primiti noutati, privind serviciile furnizate, conform consimtamantului exprimat de dumneavoastra.</p>
<p>Va puteti exprima consimtamantul pentru prelucrarea datelor in acest scop prin completarea si bifarea casutei corespunzatoare din formularul pentru furnizarea comunicarilor comerciale disponibil pe Website. </p>
<p>De asemenea, va puteti retrage consimtamantul in orice moment, fara insa a afecta legalitatea activitatilor de prelucrare realizate pana in momentul respectiv. Odata cu fiecare mesaj de marketing pe care vi-l trimitem, va vom oferi posibilitatea de a va retrage consimtamantul prin intermediul butonului de dezabonare din cadrul fiecarei comunicari comerciale.</p>
<p>Furnizarea datelor dumneavoastra in acest scop este voluntara. Refuzul furnizarii consimtamantului pentru prelucrarea datelor dumneavoastra in acest scop nu va avea urmari negative pentru dumneavoastra.

</p>

<table>
<tr>
    <td>Descrierea prelucrarii</td>
    <td>Scopul prelucrarii</td>
    <td>Temeiul legal conform RGPD</td>
  </tr>
  <tr>
    <td>Transmiterea, prin intermediul adresei de e-mail, sms, apel telefonic, posta, platforme de socializare, de comunicari comerciale privind produsele si serviciile oferite de Cabinetul medicale </td>
    <td>Marketing direct
 </td>
    <td>Art. 6 al. 1 lit. a - Consimtamantul dumneavoastra, atunci cand alegeti sa il exprimati </td>
  </tr>
 
 
</table>

<p>Cu toate acestea, in masura in care ne-ati comunicat adresa dumneavoastra de e-mail cu ocazia achizitionarii unui serviciu de la noi, putem folosi adresa de e-mail, in conformitate cu prevederile legale in vigoare care ne permit sa va transmitem comunicari comerciale cu privire la produse sau servicii similare, pe baza interesului nostru legitim.</p>
<p>In acest caz, la obtinerea adresei de e-mail de la dumneavoastra si ulterior, odata cu fiecare mesaj de marketing pe care vi-l putem trimite, va vom oferi posibilitatea de a va opune acestei utilizari a adresei de e-mail prin intermediul butonului de dezabonare din cadrul fiecarei comunicari comerciale.</p>

<li><strong>Apararea drepturilor Cabinetului medical</strong></li>
<p><strong>Datele cu caracter personal prelucrate:</strong></p>

<table>
<tr>
    <td>Descrierea prelucrarii</td>
    <td>Scopul prelucrarii</td>
    <td>Temeiul legal conform RGPD</td>
  </tr>
  <tr>
    <td>Apararea drepturilor Cabinetului medical in justitie / fata de autoritati / institutii de control </td>
    <td>Exercitarea oricaror aparari/ drepturi ale Cabinetului medical in fata instantelor de judecata sau autoritatilor sau institutiilor publice / de control (recuperarea unor sume datorate, protejarea intereselor impotriva unor pretentii/ reclamatii), in demersuri precum: formularea de intampinari, concluzii scrise, cereri.
 </td>
    <td>Art. 6 al. 1 lit. f – Interesul legitim al Cabinetului medical constand in necesitatea apararii drepturilor acesteia </td>
  </tr>
 
 
</table>
</ol>
<li><strong>UTILIZAREA COOKIE-URILOR SI A TEHNOLOGIILOR SIMILARE</strong></li>
<p>
Atunci cand vizitati Website-ul, putem utiliza cookie-uri si/sau tehnologii similare, fisiere care permit recunoasterea echipamentului utilizatorului si afisarea in mod corespunzator a Website-ului, acesta putand fi adaptata preferintelor individuale ale Vizitatorului.
Principalul scop al utilizarii cookie-urilor este sa va ajute sa aveti parte de o experienta de navigare imbunatatita si, de asemenea, in scopuri de publicitate, pentru a va putea oferi un continut individualizat si adaptat intereselor si preferintelor dumneavoastra. Drept urmare, in masura in care v-ati exprimat acordul cu privire la utilizarea diferitelor categorii de cookie-uri sau celorlalte tehnologii similare, le vom utiliza pentru a va oferi o experienta personalizata si relevanta de navigare pe si pentru a intelege modul in care interactionati cu continutul nostru publicitar.
Pentru mai multe informatii despre utilizarea cookie-urilor pe Website si scopurile in care acestea sunt utilizate, precum si cu privire la posibilitatile de a controla si/sau dezactiva cookie-urile, va rugam sa consultati Politica de utilizarea modulelor cookie pe Website, accesibila aici.....
Atunci cand vizitati Website-ul, putem utiliza cookie-uri si/sau tehnologii similare, fisiere care permit recunoasterea echipamentului Vizitatorului si afisarea in mod corespunzator a Website-ului, acesta putand fi adaptata preferintelor individuale ale utilizatorului.
Pentru mai multe informatii despre utilizarea cookie-urilor pe Website si scopurile in care acestea sunt utilizate, precum si cu privire la posibilitatile de a controla si/sau dezactiva cookie-urile, va rugam sa consultati Politica de utilizarea modulelor cookie pe Website, accesibila aici.
Prelucrarea datelor prin intermediul unora dintre modulele cookies are la baza consimtamantul dumneavoastra. Daca nu va exprimati acordul pentru plasarea acestor cookies puteti folosi Websiteul fara nicio consecinta negativa pentru dumneavoastra.
</p>

<li><strong>CATEGORII DE DESTINATARI</strong></li>

<p>Datele dumneavoastra cu caracter personal vor putea fi dezvaluite, strict in masura in care este necesar pentru scopurile detaliate anterior sau in cazurile in care acest lucru este cerut de lege, catre urmatoarele categorii de destinatari:</p>
<ul className={styles["bullet"]}>
<li>autoritati competente (ex.: organe fiscale, autoritati din domeniul sanatatii, instante de judecata);</li>
<li>societatile imputernicite sa presteze prelucrari in numele si pentru noi, cum ar fi furnizorii nostri de servicii (servicii de mentenanta a sistemelor IT, furnizorii de servicii de gazduire web etc.);</li>
<li>furnizori de servicii legate de prestarea serviciilor medicale (ex.: laboratoare de analize);</li>
<li>consultanti externi (ex.: avocati, specialisti din domeniile audit, juridic, consultanta fiscala, contabilitate);</li>
<li>entitati terte - implicate in furnizarea serviciilor aferente cookie-urilor si tehnologiilor similare, astfel cum sunt acestea detaliate in Politica de utilizarea modulelor cookie pe Website, disponibila pe Website;</li>
<li>terte parti indicate expres de dumneavoastra;</li>
<li>terti achizitori, in masura in care activitatea noastra ar fi transferata (in totalitate sau partial), iar datele persoanelor vizate ar fi parte din activele care fac obiectul unei astfel de tranzactii;</li>
<li>alti furnizori de servicii (spre exemplu, furnizori de servicii de marketing).</li>


</ul>

<li><strong>DURATA PRELUCRARII DATELOR</strong></li>

<p>Cabinetul medical va prelucra datele cu caracter personal pe durata indeplinirii scopurilor precizate in prezenta Politica, precum si ulterior in vederea conformarii cu obligatiile legale aplicabile. 
Ca principiu, Cabinetul medical va prelucra datele cu caracter personal pe intreaga durata a raporturilor contractuale, iar ulterior datele personale vor fi stocate pentru perioadele aplicabile diverselor tipuri de date cu caracter personal, stabilite in conformitate cu dispozitiile legale aplicabile, respectiv pe durata necesara indeplinirii obligatiilor legale care incumba Cabinetului medical (de exemplu, pentru datele medicale, conform legislatiei privind arhivarea dosarelor medicale; in cazul documentelor justificative financiar-contabile pentru care termenul de pastrare prevazut de lege este de 5 ani calculati de la data de 1 iulie a anului urmator celui incheierii exercitiului financiar in care au fost intocmite). 
Daca prelucrarea are loc in temeiul consimtamantului dumneavoastra, in cazul in care va retrageti consimtamantul pentru prelucrarea datelor si nu mai exista alte temeiuri juridice pentru prelucrare (de exemplu, existenta unei obligatii legale, existenta unei relatii contractuale, interesul legitim al Cabinetului medical) sau va opuneti prelucrarii si nu exista motive legitime si imperioase pentru prelucrare care sa prevaleze, Cabinetul medical va inceta prelucrarea datelor dumneavoastra cu caracter personal. 
Ulterior finalizarii perioadei de stocare conform prevederilor de mai sus, Cabinetul medical poate sa dispuna anonimizarea acestor date, lipsindu-le astfel de caracterul personal si sa continue prelucrarea datelor anonime pentru scopuri statistice
</p>

<li><strong>TRANSFERUL DATELOR CU CARACTER PERSONAL INTR-UN STAT TERT</strong></li>
<p>Nu vom transfera datele dumneavoastra cu caracter personal in afara Spatiului Economic European. In situatii de exceptie si doar daca este necesar, transferul datelor dumneavoastra cu caracter personal in afara Spatiului Economic European se va face doar cu aplicarea unor masuri de protectie adecvate conform prevederilor legale specifice in materia protectiei datelor cu caracter personal si cu informarea dumneavoastra corespunzatoare.</p>

<li><strong>SECURITATEA PRELUCRARII DATELOR </strong></li>
<p>Va informam ca am implementat masuri tehnice si organizatorice adecvate in vederea asigurarii unui nivel de securitate corespunzator datelor cu caracter personal prelucrate. 
La evaluarea nivelului adecvat de securitate, tinem seama in special de riscurile prezentate de prelucrarea datelor cu caracter personal, generate in special, in mod accidental sau ilegal, de distrugerea, pierderea, modificarea, divulgarea neautorizata sau accesul neautorizat la datele cu caracter personal transmise, stocate sau prelucrate in orice alt mod. 
De asemenea, ne asiguram ca accesul la datele cu caracter personal este acordat personalului nostru numai in masura in care acesta este necesar pentru indeplinirea atributiilor de munca.
</p>

<li><strong>PROCESE DECIZIONALE AUTOMATIZATE </strong></li>
<p>In masura in care indeplinirea unora dintre scopurile de prelucrare de mai sus se va realiza prin utilizarea unor tehnici informatice care nu implica prezenta unui factor uman, ce va duce la producerea unor efecte juridice asupra dumneavoastra sau care va pot afecta in mod similar in mod semnificativ, prelucrarea va avea la baza consimtamantul dumneavoastra expres pentru acest scop sau un alt temei legal, conform cerintelor legale. In acest sens, prezentul document va fi actualizat de fiecare data cand este necesar pentru a asigura informarea dumneavoastra corespunzatoare.
Pentru detalii cu privire la procesele decizionale automatizate, va puteti adresa la urmatoarea adresa: dr.cosmin.musuroi@gmail.com
</p>
<li><strong>DREPTURILE DE CARE BENEFICIATI</strong></li>
<p>In contextul prelucrarii datelor dumneavoastra cu caracter personal, aveti urmatoarele drepturi:</p>

<ul className={styles["bullet"]}>
<li><strong>Dreptul de acces</strong></li>
<p>Dreptul dumneavoastra de a obtine de la Cabinetul medical confirmarea daca datele cu caracter personal ce va apartin sunt prelucrate sau nu si informatii privind scopul prelucrarii, categoriile de date cu caracter personal in cauza, destinatarii si categoriile de destinatari caruia i-au fost sau le vor fi dezvaluite datele cu caracter personal si garantiile legate de aceasta divulgare, in special in cazul destinatarilor din tari terte sau organizatii internationale, perioada prevazuta pentru stocarea datelor cu caracter personal, informatii privind rectificarea, stergerea, restrictionarea si obiectii cu privire la datele cu caracter personal ce va apartin, dreptul de a depune o plangere la Autoritatea de Supraveghere si cu privire la existenta unui mecanism de luare a unei decizii automate, inclusiv a realizarii profilului.</p>

<li><strong>Dreptul de rectificare</strong></li>
<p>Aplicabil in cazul in care datele cu caracter personal sunt incorecte sau incomplete.</p>
<li><strong>Dreptul la stergere</strong></li>
<p>In cazul in care datele cu caracter personal nu mai sunt necesare in legatura cu scopurile prezentate in prezenta nota de informare, daca va retrageti consimtamantul si nu exista alte temeiuri legale pentru prelucrare, daca va opuneti prelucrarii si nu exista alte temeiuri juridice pentru prelucrare, daca datele cu caracter personal au fost prelucrate ilegal sau trebuie sa fie sterse pentru respectarea unei obligatii legale care ii incumba Cabinetului medical.
Nota: Este posibil ca unele dintre datele dumneavoastra cu caracter personal sa nu poata fi sterse, date fiind obligatiile legale sau interesul legitim de a le prelucra in continuare. Mai multe detalii privind acest subiect va vor fi furnizate la cerere.
</p>
<li><strong>Dreptul la restrictionarea prelucrarii</strong></li>

<p>In cazul in care contestati acuratetea datelor cu caracter personal, prelucrarea datelor cu caracter personal este ilegala si va opuneti stergerii datelor cu caracter personal, Cabinetul medical/ nu mai are nevoie de datele cu caracter personal pentru scopurile prezentate, dar sunt solicitate de catre dumneavoastra pentru stabilirea, exercitarea sau apararea unui drept in instanta, va opuneti prelucrarii in intervalul de timp in care se verifica daca interesul legitim al Cabinetului medical este superior interesului dumneavoastra.</p>

<li><strong>Dreptul la portabilitate</strong></li>

<p>Dreptul de a primi datele dumneavoastra cu caracter personal sau pe care dumneavoastra le-ati furnizat Cabinetului medical intr-o maniera structurata, utilizata in mod obisnuit si care poate fi citita automat, precum si dreptul de a trimite aceste date unui alt operator.</p>
<li><strong>Dreptul de a va opune prelucrarii</strong></li>
<p>Aplicabil in cazul prelucrarilor avand ca temei legal art. 6 alin. (1) lit. (e) R.G.P.D. – prelucrarea este necesara pentru indeplinirea unei sarcini care serveste unui interes public sau care rezulta din exercitarea autoritatii publice cu care este investit operatorul sau (art. 6 alin. (1) lit. (f) R.G.P.D – prelucrarea este necesara in scopul intereselor legitime urmarite de operator sau de o parte terta. 
Aplicabil cu exceptia cazului in care Cabinetul medical demonstreaza motive legitime imperioase pentru prelucrarea datelor dumneavoastra cu caracter personal, ce depasesc interesele, drepturile si libertatile dumneavoastra sau pentru stabilirea, exercitarea sau apararea unui drept in instanta.
</p>
<li><strong>Dreptul de a va retrage consimtamantul</strong></li>
<p>Dreptul de a va retrage consimtamantul in orice moment, in cazul in care prelucrarea se bazeaza pe consimtamantul dumneavoastra, fara a afecta legalitatea prelucrarii bazate pe consimtamant inainte de retragerea acestuia.</p>
<li><b>Dreptul de a nu face obiectul unei decizii bazate exclusiv pe prelucrarea automata</b>, inclusiv crearea de profiluri, care produce efecte juridice asupra dumneavoastra sau va afecteaza in mod similar, cu exceptia cazului in care o astfel de prelucrare este necesara pentru executarea contractului de prestari servicii medicale incheiat cu dumneavoastra, este permisa de lege sau are la baza consimtamantului dumneavoastra explicit;</li>
<li><strong>Dreptul de a va adresa justitiei / de a depune o plangere la Autoritatea de Protectie a Datelor (ANSPDCP) si dreptul de a va adresa instantelor de judecata competente</strong></li>

</ul>
<p>In cazul in care considerati ca noi prelucram datele dumneavoastra cu incalcarea RGPD, aveti si urmatoarele drepturi:</p>
<ol className={styles["i"]}>
<li>dreptul de a depune plangere la ANSPDCP (Autoritatea Nationala de Supraveghere a Prelucrarii Datelor cu Caracter Personal, sediu: Bucuresti, Bdul Gen. Gheorghe Magheru nr. 28-30, sector 1, CP 010336; Telefon: +40.318.05.92.11, Fax: +40.318.05.96.02 email: anspdcp@dataprotection.ro, website: www.dataprotection.ro);</li>
<li>dreptul de a formula actiune in justitie.</li>
</ol>

<p>Daca va exercitati drepturile prin mijloace electronice, Cabinetul medical va va transmite informatiile solicitate prin acelasi mijloc de comunicare intr-un format accesibil, daca nu indicati alt mijloc de contact sau ne solicitati sa va furnizam informatiile in alt format.</p>
<p>Cabinetul medical va depune eforturi sa raspunda intr-un termen de o luna de la depunerea cererii. Daca prelucrarea cererii dumneavoastra dureaza mai mult, va vom instiinta in acelasi termen in legatura cu motivele si momentul in care va  putem raspunde.</p>
<p>In cazul in care nu putem da curs cererii dumneavoastra, va vom informa cu privire la motivele aferente, sub rezerva oricaror restrictii legale sau de reglementare.  </p>
<p>Drepturile de mai sus, pot fi exercitate in orice moment. Pentru exercitarea acestor drepturi, cu exceptia dreptului de a depune o plangere la ANSPDCP, puteti sa adresati o solicitare in scris, datata si semnata sau in format electronic la urmatoarea adresa: 
MOIECIU DE JOS, Str. DN 73F, Nr. 351A, județ BRAȘOV sau prin e-mail la <a href="mailto:dr.cosmin.musuroi@gmail.com">dr.cosmin.musuroi@gmail.com.</a></p>
<p>De asemenea, in cazul in care doriti sa va retrageti consimtamantul acordat cu privire la scopurile de marketing direct, aveti posibilitatea de a utiliza optiunea „dezabonare” care este inclusa in fiecare comunicare de marketing.
</p>







</ol>

</div>

</>



)

}